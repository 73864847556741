var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('div',{attrs:{"id":"app-background"}}),_c('v-content',[_c('v-col',{staticClass:"pa-0 white"},[(_vm.$vuetify.breakpoint.smAndUp)?_c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-flex',{attrs:{"xs12":"","sm2":"","px-1":""}},[_c('v-img',{attrs:{"src":require('./assets/logo.svg')}})],1),_c('v-flex',{attrs:{"shrink":""}},[_c('v-row',{staticClass:"fill-height mx-3",attrs:{"no-gutters":"","align":"center"}},[_c('v-flex',[_c('h2',[_vm._v("Your Complete Steering Column Source")]),_c('h3',[_vm._v("Steering Columns And Parts Shipped Worldwide")]),_c('v-row',{staticClass:"accent--text",attrs:{"no-gutters":""}},[_c('h3',[_vm._v("Toll Free 888-326-5866")]),_c('div',{staticClass:"px-1"},[_vm._v("—")]),_c('h3',[_vm._v("Satisfaction Guaranteed")])])],1)],1)],1)],1)],1):_vm._e(),_c('v-tabs',{attrs:{"centered":"","show-arrows":""}},[_c('v-tab',{key:"Home",attrs:{"to":"/","exact":""}},[(_vm.$vuetify.breakpoint.xsOnly)?_c('v-avatar',{attrs:{"tile":""}},[_c('v-img',{attrs:{"src":require('./assets/logo.svg'),"contain":""}})],1):_c('span',[_vm._v("Home")])],1),_vm._l((_vm.pages.primary),function(ref){
var name = ref.name;
var page = ref.page;
return [_c('v-tab',{key:name,attrs:{"to":page}},[_vm._v(" "+_vm._s(name)+" ")])]}),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var value = ref.value;
return [_c('v-tab',_vm._g({},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(value ? _vm.mdiClose : _vm.mdiMenu)+" ")]),_vm._v(" More ")],1)]}}])},[_c('v-list',[_vm._l((_vm.pages.secondary),function(ref){
var name = ref.name;
var page = ref.page;
return [_c('v-list-item',{key:name,attrs:{"to":page}},[_c('v-list-item-title',[_vm._v(_vm._s(name))])],1)]})],2)],1)],2)],1),_c('v-container',{staticClass:"py-5",attrs:{"fluid":""}},[_c('router-view',{key:_vm.$route.fullPath})],1)],1),_c('v-footer',{attrs:{"app":"","absolute":"","color":"white"}},[_c('v-container',[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{staticClass:"grow",attrs:{"cols":"12","md":"auto"}},[_c('v-card',{staticClass:"pa-3 fill-height",attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Phone")]),_c('v-card-text',{},_vm._l((_vm.phoneNumbers),function(ref,i){
var name = ref.name;
var number = ref.number;
return _c('p',{key:i},[_vm._v(" "+_vm._s(name)+" "),_c('a',{staticClass:"ml-2",staticStyle:{"float":"right"},attrs:{"href":_vm.toCallable(number)}},[_vm._v(_vm._s(number))])])}),0)],1)],1),_c('v-col',{staticClass:"grow",attrs:{"cols":"12","md":"auto"}},[_c('v-card',{staticClass:"pa-3 fill-height",attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Shipping & Mailing")]),_c('v-card-text',[_c('p',[_vm._v("Steering Columns Galore Inc.")]),_c('p',[_vm._v("5 Progress Street")]),_c('p',[_vm._v("Brewster, NY 10509")])])],1)],1),_c('v-col',{staticClass:"grow",attrs:{"cols":"12","md":"auto"}},[_c('v-card',{staticClass:"pa-3 fill-height",attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Email")]),_c('v-card-text',[_c('p',[_c('a',{attrs:{"href":"mailto:techsupport@columnsgalore.com"}},[_vm._v("techsupport@columnsgalore.com")])]),_c('p',[_c('a',{attrs:{"href":"mailto:salesteam@columnsgalore.com"}},[_vm._v("salesteam@columnsgalore.com")])])])],1)],1),_c('v-col',{staticClass:"grow",attrs:{"cols":"12","md":"auto"}},[_c('v-card',{staticClass:"pa-3 fill-height",attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Hours of Operation (EST)")]),_c('v-card-text',[_c('v-simple-table',{attrs:{"dense":"","width":"100%"}},[_c('tbody',_vm._l((_vm.hours),function(ref,i){
var day = ref.day;
var start = ref.start;
var end = ref.end;
return _c('tr',{key:i},[_c('td',[_vm._v(_vm._s(day))]),_c('td',[_vm._v(_vm._s(start))]),_c('td',[_vm._v(_vm._s(end))])])}),0)])],1)],1)],1),_c('v-col',{staticClass:"grow",attrs:{"cols":"12","md":"auto"}},[_c('v-card',{staticClass:"fill-height grow",attrs:{"outlined":""}},[_c('v-card-actions',{staticClass:"pa-0"},[_c('iframe',{staticStyle:{"border":"0"},attrs:{"src":"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2993.03556457633!2d-73.62045168448964!3d41.3950340034838!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89dd52e5ff79a0cb%3A0x487f9a4fb8e078ea!2sSteering%20Columns%20Galore%20Inc.!5e0!3m2!1sen!2sus!4v1578945739765!5m2!1sen!2sus","width":"100%","height":"300","frameborder":"0","allowfullscreen":""}})])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }